var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Toast } from 'common/core/ui/toast.service';
import { Themes } from '../themes/themes.service';
import { Project } from '../projects/Project';
import { Projects } from '../projects/projects.service';
import { PageDocument } from '../page-document';
import { Templates } from '../templates/templates.service';
import { ProjectUrl } from '../projects/project-url.service';
import { forkJoin } from 'rxjs';
import { randomString } from '../../../common/core/utils/random-string';
var DEFAULT_FRAMEWORK = 'bootstrap-3';
var CrupdateProjectModalComponent = /** @class */ (function () {
    function CrupdateProjectModalComponent(dialogRef, data, projects, toast, themesApi, templatesApi, projectUrl) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.projects = projects;
        this.toast = toast;
        this.themesApi = themesApi;
        this.templatesApi = templatesApi;
        this.projectUrl = projectUrl;
        this.themes = [];
        this.templates = [];
        this.loading = false;
        this.updating = false;
        this.errors = {};
        this.pageDocument = new PageDocument();
    }
    CrupdateProjectModalComponent.prototype.ngOnInit = function () {
        if (this.data.showExtraConfig) {
            this.getThemesAndTemplates();
        }
        this.hydrateModel();
        this.pageDocument.setBaseUrl(this.projectUrl.getBaseUrl(this.model));
        this.updating = !!this.data.project;
    };
    CrupdateProjectModalComponent.prototype.confirm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request, _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.loading = true;
                        if (!this.updating) return [3 /*break*/, 1];
                        request = this.projects.update(this.data.project.id, this.model);
                        return [3 /*break*/, 3];
                    case 1:
                        _b = (_a = this.projects).create;
                        return [4 /*yield*/, this.getNewProjectPayload()];
                    case 2:
                        request = _b.apply(_a, [_c.sent()]);
                        _c.label = 3;
                    case 3:
                        request.subscribe(function (response) {
                            _this.close(response.project);
                            var action = _this.updating ? 'updated' : 'created';
                            _this.toast.open('Project has been ' + action);
                            _this.loading = false;
                        }, function (response) {
                            _this.errors = response.messages;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    CrupdateProjectModalComponent.prototype.close = function (data) {
        this.dialogRef.close(data);
    };
    CrupdateProjectModalComponent.prototype.hydrateModel = function () {
        this.errors = {};
        this.model = new Project({
            pages: [],
            template: this.data.templateName || null,
            framework: !this.data.templateName ? DEFAULT_FRAMEWORK : null,
            uuid: randomString(36)
        });
        if (this.data.project) {
            this.model.name = this.data.project.name;
            this.model.framework = this.data.project.framework;
            this.model.theme = this.data.project.theme;
        }
    };
    CrupdateProjectModalComponent.prototype.getThemesAndTemplates = function () {
        var _this = this;
        forkJoin(this.themesApi.all(), this.templatesApi.all()).subscribe(function (response) {
            _this.themes = response[0].themes;
            _this.templates = response[1].pagination.data;
        });
    };
    CrupdateProjectModalComponent.prototype.getNewProjectPayload = function () {
        var templateName = this.data.templateName || this.model.template;
        if (templateName) {
            return this.createProjectFromTemplate(templateName);
        }
        else {
            return this.createBlankProject();
        }
    };
    CrupdateProjectModalComponent.prototype.createProjectFromTemplate = function (templateName) {
        var _this = this;
        return new Promise(function (resolve) {
            var params = _this.model;
            _this.templatesApi.get(templateName).subscribe(function (response) {
                params.template = response.template;
                params.framework = response.template.config.framework;
                params.pages = _this.transformTemplatePages(response.template);
                resolve(params);
            });
        });
    };
    CrupdateProjectModalComponent.prototype.createBlankProject = function () {
        var params = this.model;
        params.pages.push({
            name: 'index',
            html: this.pageDocument.generate().getOuterHtml()
        });
        return params;
    };
    CrupdateProjectModalComponent.prototype.transformTemplatePages = function (template) {
        var _this = this;
        return template.pages.map(function (page) {
            return {
                name: page.name,
                html: _this.pageDocument.generate(page.html, template).getOuterHtml(),
            };
        });
    };
    return CrupdateProjectModalComponent;
}());
export { CrupdateProjectModalComponent };
