import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Settings } from '../config/settings.service';
import { Translations } from '../translations/translations.service';
import { ucFirst } from '../utils/uc-first';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "../config/settings.service";
import * as i4 from "../translations/translations.service";
var TAG_CLASS = 'dst';
var MetaTagsService = /** @class */ (function () {
    function MetaTagsService(document, router, settings, i18n, route) {
        this.document = document;
        this.router = router;
        this.settings = settings;
        this.i18n = i18n;
        this.route = route;
        this.latestMetaTags$ = new BehaviorSubject(null);
    }
    MetaTagsService.prototype.init = function () {
        var _this = this;
        // clear previous route meta tags
        this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationStart; }))
            .subscribe(function () {
            _this.latestMetaTags$.next(null);
        });
        this.activeRouteData$()
            .subscribe(function (routeData) {
            // meta tags were fetched with route resolver
            if (_this.latestMetaTags$.value) {
                _this.addTags(_this.latestMetaTags$.value);
                // route will fetch meta tags via ajax, wait for it
            }
            else if (routeData.willSetSeo) {
                _this.latestMetaTags$.pipe(filter(function (tags) { return !!tags; }), take(1)).subscribe(function (tags) {
                    _this.addTags(tags);
                });
                // route does not have specific meta tags, set default ones
            }
            else {
                _this.setDefaultTags(routeData);
            }
        });
    };
    MetaTagsService.prototype.addTags = function (tags) {
        var _this = this;
        this.removeOldTags();
        var firstChild = this.document.head.firstChild;
        tags.forEach(function (tag) {
            var node = document.createElement(tag.nodeName);
            node.classList.add(TAG_CLASS);
            Object.keys(tag).forEach(function (key) {
                if (key === 'nodeName')
                    return;
                if (key === '_text') {
                    node.textContent = typeof tag[key] === 'string' ? tag[key] : JSON.stringify(tag[key]);
                }
                else {
                    node.setAttribute(key, tag[key]);
                }
            });
            _this.document.head.insertBefore(node, firstChild);
        });
    };
    MetaTagsService.prototype.removeOldTags = function () {
        var tags = this.document.head.getElementsByClassName(TAG_CLASS);
        for (var i = 0; i < tags.length; i++) {
            this.document.head.removeChild(tags[i]);
        }
    };
    MetaTagsService.prototype.activeRouteData$ = function () {
        var _this = this;
        return this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationEnd; }), map(function () { return _this.route; }), map(function (route) {
            while (route.firstChild)
                route = route.firstChild;
            return route;
        }), filter(function (route) { return route.outlet === 'primary'; }), map(function (route) { return route.snapshot.data; }));
    };
    MetaTagsService.prototype.setDefaultTags = function (data) {
        var title = {
            nodeName: 'title',
            _text: this.settings.get('branding.site_name'),
        };
        var defaultTitle = data.title || data.name;
        // prepend route name to site name, if available
        if (defaultTitle) {
            var name_1 = this.i18n.t(defaultTitle.replace('-', ' '));
            title._text = name_1 + ' - ' + title._text;
        }
        title._text = ucFirst(title._text);
        this.addTags([title]);
    };
    MetaTagsService.ngInjectableDef = i0.defineInjectable({ factory: function MetaTagsService_Factory() { return new MetaTagsService(i0.inject(i1.DOCUMENT), i0.inject(i2.Router), i0.inject(i3.Settings), i0.inject(i4.Translations), i0.inject(i2.ActivatedRoute)); }, token: MetaTagsService, providedIn: "root" });
    return MetaTagsService;
}());
export { MetaTagsService };
