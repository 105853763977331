var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UploadValidation } from './upload-validation';
var FileTypeValidation = /** @class */ (function (_super) {
    __extends(FileTypeValidation, _super);
    function FileTypeValidation(params, i18n) {
        var _this = _super.call(this) || this;
        _this.params = params;
        _this.i18n = i18n;
        _this.errorMessage = _this.params.types.length > 1 ?
            _this.getPluralMessage() :
            _this.getSingularMessage();
        return _this;
    }
    FileTypeValidation.prototype.fails = function (file) {
        return !this.params.types.some(function (type) {
            return type === file.mime.split('/')[0];
        });
    };
    FileTypeValidation.prototype.getSingularMessage = function () {
        return this.i18n.t('File must be a :type.', { type: this.i18n.t(this.params.types[0]) });
    };
    FileTypeValidation.prototype.getPluralMessage = function () {
        return this.i18n.t('File must be one of these types: :types.', { types: this.i18n.t(this.params.types.join(', ')) });
    };
    return FileTypeValidation;
}(UploadValidation));
export { FileTypeValidation };
