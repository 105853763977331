import { AppHttpClient } from "common/core/http/app-http-client.service";
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var Templates = /** @class */ (function () {
    /**
     * Templates API service constructor.
     */
    function Templates(http) {
        this.http = http;
    }
    /**
     * Get all available templates.
     */
    Templates.prototype.all = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.get('templates', params);
    };
    /**
     * Get template by specified id.
     */
    Templates.prototype.get = function (name) {
        return this.http.get('templates/' + name);
    };
    /**
     * Create a new template.
     */
    Templates.prototype.create = function (params) {
        return this.http.post('templates', params);
    };
    /**
     * Update specified template.
     */
    Templates.prototype.update = function (name, params) {
        return this.http.put('templates/' + name, params);
    };
    /**
     * Delete specified templates.
     */
    Templates.prototype.delete = function (names) {
        return this.http.delete('templates', { names: names });
    };
    Templates.ngInjectableDef = i0.defineInjectable({ factory: function Templates_Factory() { return new Templates(i0.inject(i1.AppHttpClient)); }, token: Templates, providedIn: "root" });
    return Templates;
}());
export { Templates };
