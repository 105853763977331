import { map } from 'rxjs/operators';
import { AppHttpClient } from '../core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
var Users = /** @class */ (function () {
    function Users(http) {
        this.http = http;
    }
    /**
     * Get user matching specified ID.
     */
    Users.prototype.get = function (id, params) {
        return this.http.get("users/" + id, params);
    };
    /**
     * Fetch users without pagination.
     */
    Users.prototype.getAll = function (params) {
        if (params === void 0) { params = null; }
        return this.http.get('users', params).pipe(map(function (response) { return response['pagination']['data']; }));
    };
    /**
     * Create a new user.
     */
    Users.prototype.create = function (payload) {
        return this.http.post('users', payload);
    };
    /**
     * Update existing user.
     */
    Users.prototype.update = function (id, payload) {
        return this.http.put('users/' + id, payload);
    };
    /**
     * Change specified user password.
     */
    Users.prototype.changePassword = function (id, payload) {
        return this.http.post('users/' + id + '/password/change', payload);
    };
    /**
     * Attach specified roles to user.
     */
    Users.prototype.attachRoles = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/roles/attach', payload);
    };
    /**
     * Detach specified roles from user.
     */
    Users.prototype.detachRoles = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/roles/detach', payload);
    };
    /**
     * Add specified permissions to user.
     */
    Users.prototype.addPermissions = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/permissions/start', payload);
    };
    /**
     * Remove specified permissions from user.
     */
    Users.prototype.removePermissions = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/permissions/remove', payload);
    };
    /**
     * Upload and attach avatar to specified user.
     */
    Users.prototype.uploadAvatar = function (id, files) {
        var payload = new FormData();
        payload.append('avatar', files[0].native);
        return this.http.post('users/' + id + '/avatar', payload);
    };
    /**
     * Delete specified user's avatar.
     */
    Users.prototype.deleteAvatar = function (id) {
        return this.http.delete('users/' + id + '/avatar');
    };
    /**
     * Delete multiple users.
     */
    Users.prototype.deleteMultiple = function (ids) {
        return this.http.delete('users/delete-multiple', { ids: ids });
    };
    //
    /**
     * Sync specified user tags.
     */
    Users.prototype.syncTags = function (id, payload) {
        return this.http.post('users/' + id + '/tags/sync', payload);
    };
    /**
     * Update details about user.
     */
    Users.prototype.updateDetails = function (id, payload) {
        return this.http.put('users/' + id + '/details', payload);
    };
    /**
     * Add secondary email to specified user.
     */
    Users.prototype.addEmail = function (id, payload) {
        return this.http.post('users/' + id + '/emails/attach', payload);
    };
    /**
     * Remove secondary email from specified user.
     */
    Users.prototype.removeEmail = function (id, payload) {
        return this.http.post('users/' + id + '/emails/detach', payload);
    };
    Users.ngInjectableDef = i0.defineInjectable({ factory: function Users_Factory() { return new Users(i0.inject(i1.AppHttpClient)); }, token: Users, providedIn: "root" });
    return Users;
}());
export { Users };
