import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { MetaTagsService } from './meta-tags.service';
import * as i0 from "@angular/core";
import * as i1 from "./meta-tags.service";
var MetaTagsInterceptor = /** @class */ (function () {
    function MetaTagsInterceptor(metaTags) {
        this.metaTags = metaTags;
    }
    MetaTagsInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(tap(function (e) {
            if (e instanceof HttpResponse && e.body && e.body.seo) {
                _this.metaTags.latestMetaTags$.next(e.body.seo);
            }
            return e;
        }));
    };
    MetaTagsInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function MetaTagsInterceptor_Factory() { return new MetaTagsInterceptor(i0.inject(i1.MetaTagsService)); }, token: MetaTagsInterceptor, providedIn: "root" });
    return MetaTagsInterceptor;
}());
export { MetaTagsInterceptor };
