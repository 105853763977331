import { MatSnackBar } from '@angular/material';
import { Settings } from '../config/settings.service';
import { Translations } from '../translations/translations.service';
import { HttpErrors } from '../http/errors/http-errors.enum';
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
import * as i2 from "../translations/translations.service";
import * as i3 from "@angular/material/snack-bar";
var Toast = /** @class */ (function () {
    function Toast(settings, i18n, snackbar) {
        this.settings = settings;
        this.i18n = i18n;
        this.snackbar = snackbar;
    }
    Toast.prototype.open = function (message, config) {
        if (config === void 0) { config = {}; }
        if (!config.duration && config.duration !== 0) {
            config.duration = this.settings.get('toast.default_timeout', 3000);
        }
        if (!message) {
            message = HttpErrors.Default;
        }
        var translatedMsg = typeof message === 'string' ?
            this.i18n.t(message) :
            this.i18n.t(message.message, message.replacements);
        return this.snackbar.open(this.i18n.t(translatedMsg), this.i18n.t(config.action), { duration: config.duration });
    };
    Toast.prototype.openComponent = function (component, config) {
        return this.snackbar.openFromComponent(component, config);
    };
    Toast.ngInjectableDef = i0.defineInjectable({ factory: function Toast_Factory() { return new Toast(i0.inject(i1.Settings), i0.inject(i2.Translations), i0.inject(i3.MatSnackBar)); }, token: Toast, providedIn: "root" });
    return Toast;
}());
export { Toast };
