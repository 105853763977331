var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter } from '@angular/core';
import { InfiniteScroll } from 'common/core/ui/infinite-scroll/infinite.scroll';
import { Templates } from '../../shared/templates/templates.service';
var TemplatesInfiniteScrollDirective = /** @class */ (function (_super) {
    __extends(TemplatesInfiniteScrollDirective, _super);
    function TemplatesInfiniteScrollDirective(el, templates) {
        var _this = _super.call(this) || this;
        _this.el = el;
        _this.templates = templates;
        _this.loadedTemplates = new EventEmitter();
        _this.loading = false;
        return _this;
    }
    TemplatesInfiniteScrollDirective.prototype.loadMoreItems = function () {
        var _this = this;
        this.templates.all({ page: this.templatePagination.current_page + 1, per_page: 25 })
            .subscribe(function (response) {
            _this.templatePagination = response.pagination;
            _this.loadedTemplates.emit(response);
        });
    };
    TemplatesInfiniteScrollDirective.prototype.isLoading = function () {
        return this.loading;
    };
    TemplatesInfiniteScrollDirective.prototype.canLoadMore = function () {
        return this.templatePagination.current_page < this.templatePagination.last_page;
    };
    return TemplatesInfiniteScrollDirective;
}(InfiniteScroll));
export { TemplatesInfiniteScrollDirective };
