var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ErrorHandler } from '@angular/core';
import * as Raven from 'raven-js';
import { ignoredErrors } from './ignored-errors';
export function noBackendErrorHandlerFactory(settings) {
    return new NoBackendErrorHandler(settings);
}
var NoBackendErrorHandler = /** @class */ (function (_super) {
    __extends(NoBackendErrorHandler, _super);
    /**
     * RavenErrorHandler Constructor.
     */
    function NoBackendErrorHandler(settings) {
        var _this = _super.call(this) || this;
        _this.settings = settings;
        /**
         * Whether sentry error logger is already installed.
         */
        _this.installed = false;
        return _this;
    }
    /**
     * Handle specified error.
     */
    NoBackendErrorHandler.prototype.handleError = function (err, options) {
        if (!err)
            return;
        _super.prototype.handleError.call(this, err);
        // sentry did not install properly
        if (!this.installSentry())
            return;
        Raven.captureException(err.originalError || err, options);
    };
    /**
     * Install sentry error logger.
     */
    NoBackendErrorHandler.prototype.installSentry = function () {
        if (this.installed)
            return true;
        // no sentry public key is set
        if (!this.settings.has('logging.sentry_public'))
            return false;
        // install
        Raven.config(this.settings.get('logging.sentry_public'), {
            release: this.settings.get('version'),
            ignoreErrors: ignoredErrors,
        }).install();
        return this.installed = true;
    };
    return NoBackendErrorHandler;
}(ErrorHandler));
export { NoBackendErrorHandler };
