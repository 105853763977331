import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Settings } from 'common/core/config/settings.service';
import { Modal } from 'common/core/ui/dialogs/modal.service';
import { CrupdateProjectModalComponent } from '../../shared/crupdate-project-modal/crupdate-project-modal.component';
import { FormControl, FormGroup } from '@angular/forms';
var NewProjectPageComponent = /** @class */ (function () {
    function NewProjectPageComponent(route, settings, modal, router) {
        this.route = route;
        this.settings = settings;
        this.modal = modal;
        this.router = router;
        this.allCategories = [];
        this.filteredTemplates = [];
        this.filterForm = new FormGroup({
            search: new FormControl(),
            category: new FormControl(null),
        });
    }
    NewProjectPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.allCategories = this.settings.getJson('builder.template_categories', []);
        this.filterForm.valueChanges.subscribe(function (value) {
            _this.applyFilters(value);
        });
        this.route.data.subscribe(function (resolve) {
            _this.templatePagination = resolve.templates;
            _this.filteredTemplates = resolve.templates.data;
        });
    };
    NewProjectPageComponent.prototype.openNewProjectModal = function (templateName) {
        var _this = this;
        this.modal.open(CrupdateProjectModalComponent, { templateName: templateName })
            .afterClosed().subscribe(function (project) {
            if (!project)
                return;
            _this.router.navigate(['/builder', project.model.id]);
        });
    };
    NewProjectPageComponent.prototype.getTemplateThumbnail = function (template) {
        return this.settings.getBaseUrl(true) + template.thumbnail;
    };
    NewProjectPageComponent.prototype.addMoreTemplates = function (templatePagination) {
        var _a;
        var oldTemplates = this.templatePagination.data.slice();
        this.templatePagination = templatePagination;
        (_a = this.templatePagination.data).push.apply(_a, oldTemplates);
        this.filteredTemplates = this.templatePagination.data;
    };
    NewProjectPageComponent.prototype.applyFilters = function (value) {
        this.filteredTemplates = this.templatePagination.data.filter(function (template) {
            var templateName = template.config.name || template.config.display_name || '';
            var matchesCategory = !value.category || template.config.category === value.category;
            var matchesSearch = !value.search || templateName.toLowerCase().indexOf(value.search.toLowerCase()) > -1;
            return matchesCategory && matchesSearch;
        });
    };
    return NewProjectPageComponent;
}());
export { NewProjectPageComponent };
