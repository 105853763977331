import { Settings } from '../config/settings.service';
import { AppHttpClient } from '../http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../http/app-http-client.service";
import * as i2 from "../config/settings.service";
var Localizations = /** @class */ (function () {
    function Localizations(http, settings) {
        this.http = http;
        this.settings = settings;
    }
    /**
     * Get all available  localizations.
     */
    Localizations.prototype.all = function () {
        return this.http.get('localizations');
    };
    /**
     * Get localization by specified name.
     */
    Localizations.prototype.get = function (name) {
        return this.http.get('localizations/' + name);
    };
    /**
     * Create new localization.
     */
    Localizations.prototype.create = function (params) {
        return this.http.post('localizations', params);
    };
    /**
     * Update specified localization.
     */
    Localizations.prototype.update = function (id, params) {
        return this.http.put('localizations/' + id, params);
    };
    /**
     * Delete specified localization.
     */
    Localizations.prototype.delete = function (id) {
        return this.http.delete('localizations/' + id);
    };
    /**
     * Set specified localization as default for new users.
     */
    Localizations.prototype.setDefault = function (name) {
        var params = { server: { app_locale: name } };
        return this.settings.save(params);
    };
    Localizations.ngInjectableDef = i0.defineInjectable({ factory: function Localizations_Factory() { return new Localizations(i0.inject(i1.AppHttpClient), i0.inject(i2.Settings)); }, token: Localizations, providedIn: "root" });
    return Localizations;
}());
export { Localizations };
